// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import {
  LogIn,
  LogOut,
  ForgotPassword,
  getAuthToken,
  Register,
  NoAccess,
  Verification,
  hasProductAccess,
} from 'roy-morgan-auth';
import { LIVE_MANAGEMENT_PRODUCT, redirectHook } from '../helpers';
import type { Location } from 'react-router-dom';

import Home from './Home';
import PrivateRoute from '../components/PrivateRoute';
import Organisations from './Organisations';
import OnlineStoreItems from './OnlineStore/OnlineStoreItems';
import OnlineStoreItem from './OnlineStore/OnlineStoreItem';
import NewOnlineStoreItem from './OnlineStore/NewOnlineStoreItem';
import Organisation from './Organisation';
import Settings from './Settings';
import PasswordSettings from './PasswordSettings';
import Users from './Users/Users';
import User from './Users/User';
import EmailNotifications from './EmailNotifications/EmailNotifications';
import EmailNotification from './EmailNotifications/EmailNotification';
import NewOrganisation from './NewOrganisation';
import ApiSubscriptions from './ApiSubscriptions/ApiSubscriptions';
import ApiSubscription from './ApiSubscriptions/ApiSubscription';
import VerifyUser from './VerifyUser';
import OnlineStoreTreeCategories from './OnlineStore/OnlineStoreTreeCategories';
import OnlineStoreOrderItems from './OnlineStore/OnlineStoreOrderItems';
import OnlineStoreOrders from './OnlineStore/OnlineStoreOrders';
import OnlineStoreOrder from './OnlineStore/OnlineStoreOrder';
import OnlineStoreCoupons from './OnlineStore/OnlineStoreCoupons';
import OnlineStoreCoupon from './OnlineStore/OnlineStoreCoupon';
import NewOnlineStoreCoupon from './OnlineStore/NewOnlineStoreCoupon';
import SelfRegistration from './SelfRegistration';
import NotAuthenticatedMessage from '../components/NotAuthenticatedMessage';
import FourOhFourMessage from '../components/FourOhFourMessage';
import TempResetPassword from './TempResetPassword';
import FlashMessage from './FlashMessage';
import { OverallUsageLogs } from './OverallUsageLogs';
import SearchableAudienceImports from './WorkSpace/SearchableAudienceImports';
import SearchableAudienceImport from './WorkSpace/SearchableAudienceImport';

type Props = {
  hasAccess: boolean,
  location: Location,
};

class Root extends React.Component<Props> {
  componentDidMount() {
    this.checkAccess();
  }

  componentDidUpdate(prevProps) {
    this.checkAccess();
  }

  checkAccess() {
    const { hasAccess } = this.props;
    const token = getAuthToken();

    if (
      !token ||
      token === '0' ||
      hasAccess === undefined ||
      window.location.pathname === '/no-access'
    ) {
      return;
    }

    if (!hasAccess) {
      window.location.replace('/no-access');
    }
  }

  render() {
    const { hasAccess } = this.props;
    const finalRedirect = redirectHook(this.props.location);
    return (
      <div className="page-container">
        <FlashMessage />
        <Switch>
          {/* Authentication */}
          <Route
            exact={true}
            path="/forgot-password"
            component={ForgotPassword}
          />
          <Route
            exact={true}
            path="/reset-password"
            component={TempResetPassword}
          />
          <Route exact={true} path="/register" component={Register} />
          <Route
            exact={true}
            path="/login"
            render={() => (
              <LogIn
                redirectTo={finalRedirect}
                product={LIVE_MANAGEMENT_PRODUCT}
              />
            )}
          />
          <Route exact={true} path="/logout" component={LogOut} />
          <Route exact={true} path="/verification" component={Verification} />
          <Route
            exact={true}
            path="/no-access"
            render={() => (
              <NoAccess
                productId={LIVE_MANAGEMENT_PRODUCT}
                productName={'Live Management'}
              />
            )}
          />
          <Route exact={true} path="/verify-email" component={VerifyUser} />
          {/* Dashboard */}
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute
            exact
            path="/usage-logs"
            component={OverallUsageLogs}
          />
          <PrivateRoute
            exact
            path="/organisations/new"
            component={NewOrganisation}
          />
          <PrivateRoute
            path="/organisations/:organisationId/collaborators/:collaboratorId/:tab"
            component={Organisation}
          />
          <PrivateRoute
            path="/organisations/:organisationId/users/:userId/:tab"
            component={Organisation}
          />
          <PrivateRoute
            path="/organisations/:organisationId/teams/:teamId/:tab"
            component={Organisation}
          />
          <PrivateRoute
            path="/organisations/:organisationId/:tab"
            component={Organisation}
          />
          <PrivateRoute
            path="/organisations/:organisationId/"
            component={Organisation}
          />
          <PrivateRoute exact={true} path="/settings" component={Settings} />
          <PrivateRoute
            exact={true}
            path="/settings/password"
            component={PasswordSettings}
          />
          <PrivateRoute path="/organisations" component={Organisations} />
          <PrivateRoute path="/storeitems/new" component={NewOnlineStoreItem} />
          <PrivateRoute
            path="/storeitems/:onlineStoreItemId"
            component={OnlineStoreItem}
          />
          <PrivateRoute
            path="/storeitems/:onlineStoreItemId/:tab"
            component={OnlineStoreItem}
          />
          <PrivateRoute path="/storeitems" component={OnlineStoreItems} />
          <PrivateRoute
            path="/storetreecategories"
            component={OnlineStoreTreeCategories}
          />
          <PrivateRoute
            path="/storeorders/:onlineStoreOrderId/:tab"
            component={OnlineStoreOrder}
          />
          <PrivateRoute
            path="/storeorders/:onlineStoreOrderId"
            component={OnlineStoreOrder}
          />
          <PrivateRoute path="/storeorders" component={OnlineStoreOrders} />
          <PrivateRoute
            path="/storeorderitems"
            component={OnlineStoreOrderItems}
          />
          <PrivateRoute
            path="/storecoupons/new"
            component={NewOnlineStoreCoupon}
          />
          <PrivateRoute
            path="/storecoupons/:onlineStoreCouponId"
            component={OnlineStoreCoupon}
          />
          <PrivateRoute
            path="/storecoupons/:onlineStoreCouponId/:tab"
            component={OnlineStoreCoupon}
          />
          <PrivateRoute path="/storecoupons" component={OnlineStoreCoupons} />
          <PrivateRoute path="/users/:userId/:tab" component={User} />
          <PrivateRoute path="/users/:userId" component={User} />
          <PrivateRoute path="/users" component={Users} />
          <PrivateRoute path="/email-notifications/:emailNotificationId" component={EmailNotification} />
          <PrivateRoute path="/email-notifications" component={EmailNotifications} />
           <PrivateRoute
            path="/api-subscriptions/:apiId/details/:organisationId/:tab"
            component={ApiSubscription}
          />
          <PrivateRoute
            path="/api-subscriptions/:apiId/:tab"
            component={ApiSubscription}
          />
          <PrivateRoute
            path="/api-subscriptions/:apiId/"
            component={ApiSubscription}
          />
          <PrivateRoute path="/api-subscriptions" component={ApiSubscriptions} />
          <PrivateRoute
            path="/workspace/searchable-audience-imports/:importId"
            component={SearchableAudienceImport}
          />
          <PrivateRoute
            path="/workspace/searchable-audience-imports/:importId/:tab"
            component={SearchableAudienceImport}
          />
          <PrivateRoute
            path="/workspace/searchable-audience-imports"
            component={SearchableAudienceImports}
          />
          <Route
            exact={true}
            path="/self-register"
            component={SelfRegistration}
          />
          {hasAccess ? (
            <Route path="/" render={(props) => <FourOhFourMessage />} />
          ) : (
            <Route
              path="/"
              render={() => (
                <NotAuthenticatedMessage
                  title="You must be logged in to view this page"
                  buttonText="Go to Log In"
                  loginUrl="login"
                />
              )}
            />
          )}
          {/* {hasAccess && (
            
          )} */}
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hasAccess:
      state.auth.products && state.auth.products.length > 0
        ? hasProductAccess(state.auth.products, 'Live Management')
        : undefined
  };
};

export default (connect(mapStateToProps)(Root): any);
