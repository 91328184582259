//@flow
import React from 'react';
import type { Organisation } from '../../types';
import { Grid, Confirm } from 'semantic-ui-react';
import OrganisationFormFields from '../../components/Organisation/OrganisationFormFields';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  updateOrganisation,
  archiveOrganisation,
  forceVerifyOrganisation,
  loadOrganisationFlags,
  loadOrganisationAccountManagers,
  addOrganisationAccountManager,
  removeOrganisationAccountManager,
  allowToHaveTeamsOrganisation,
} from '../../actions';
import { loadIndustries } from '../../actions/industries';
import {
  loadAllOrganisationTeams,
} from '../../actions/organisations/organisationTeams';
import { getIndustriesSortedAlphabetically } from '../../reducers/industries';
import { getOrganisationFlagsSortedById } from '../../reducers/organisationFlags';
import { getOrganisationAccountManagersSortedAlphabetically } from '../../reducers/organisationAccountManagers';
import type {
  Dispatch,
  Organisation as OrgType,
  Industries as IndustriesType,
  Industry as IndustryType,
  AuthTypes,
  OrganisationFlag as OrganisationFlagType,
  AccountManager as AccountManagerType,
  AccountManagers as AccountManagersType
} from '../../types';
import type { RouterHistory } from 'react-router-dom';
import PageLoader from '../../components/PageLoader';
import { restrictUserPermissions, permissions } from 'roy-morgan-auth';
import moment from 'moment';
import { getOrganisationTeamsSortedAlphabetically } from '../../reducers/organisationTeams';


type Props = {
  organisation: OrgType,
  organisationId: number,
  industries: Array<IndustryType>,
  organisationFlags: Array<OrganisationFlagType>,
  accountManagers: Array<AccountManagerType>,
  organisationTeams : ?Array<any>,
  history: RouterHistory,
  updateOrganisation: (oid: number, body: Object) => Promise<Organisation>,
  loadIndustries: () => Promise<IndustriesType>,
  loadOrganisationFlags: () => Promise<OrganisationFlagType>,
  archiveOrganisation: (oid: number) => Promise<Organisation>,
  forceVerifyOrganisation: (oid: number) => Promise<null>,
  loadOrganisationAccountManagers: (oid: number) => Promise<AccountManagersType>,
  loadAllOrganisationTeams: (
    oid: number,
    teamAccess: ?boolean
  ) => Promise<boolean>,
  addOrganisationAccountManager: (oid: number, uid: number) => Promise<AccountManagerType>,
  removeOrganisationAccountManager: (oid: number, uid: number) => Promise<boolean>,
  allowToHaveTeamsOrganisation: (data: any) => Promise<any>,
  isFetching: boolean,
  permission: ?AuthTypes,
};

type State = {
  forceVerifyOpen: boolean,
  archiveOrganisationOpen: boolean,
};

class Details extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      forceVerifyOpen: false,
      archiveOrganisationOpen: false,
    };
  }

  componentDidMount() {
    const { permission, organisationId } = this.props;
    const { INTERNAL_ADMIN, BUSINESS_ADMIN, SUPPORT_ADMIN,  ACCOUNT_MANAGER_ADMIN } = permissions;

    this.props.loadIndustries();

    if (restrictUserPermissions([INTERNAL_ADMIN, BUSINESS_ADMIN, SUPPORT_ADMIN, ACCOUNT_MANAGER_ADMIN], permission)) {
      this.props.loadOrganisationFlags();
    }

    if (organisationId &&
      restrictUserPermissions([INTERNAL_ADMIN, BUSINESS_ADMIN, SUPPORT_ADMIN, ACCOUNT_MANAGER_ADMIN], permission)
    ) {
      this.props.loadOrganisationAccountManagers(organisationId);
    }

    if (restrictUserPermissions([INTERNAL_ADMIN, BUSINESS_ADMIN, ACCOUNT_MANAGER_ADMIN], permission)) {
      this.props.loadAllOrganisationTeams(organisationId);
    }
  }

  handleOrganisationUpdate = (data) => {
    this.props.updateOrganisation(this.props.organisationId, data);
  };

  refreshOrganisationTeams = () => {
    const { permission, organisationId } = this.props;
    const { INTERNAL_ADMIN, BUSINESS_ADMIN, ACCOUNT_MANAGER_ADMIN } = permissions;
    if (restrictUserPermissions([INTERNAL_ADMIN, BUSINESS_ADMIN, ACCOUNT_MANAGER_ADMIN], permission)) {
      this.props.loadAllOrganisationTeams(organisationId);
    }
  }

  handleOrganisationArchive = () => {
    this.setState({ ...this.state, archiveOrganisationOpen: false });
    this.props.archiveOrganisation(this.props.organisationId);
  };

  handleForceVerify = () => {
    this.props.forceVerifyOrganisation(this.props.organisationId);
    this.setState({ ...this.state, forceVerifyOpen: false });
  };

  setForceVerifyModalVisible = () => {
    this.setState({ ...this.state, forceVerifyOpen: true });
  };

  setOrganisationArchiveModalVisible = () => {
    this.setState({ ...this.state, archiveOrganisationOpen: true });
  };

  render() {
    const { permission, organisation, isFetching } = this.props;
    const { INTERNAL_ADMIN, BUSINESS_ADMIN, SUPPORT_ADMIN, ACCOUNT_MANAGER_ADMIN, STANDARD_USER } = permissions;

    return (
      <div className="tabbed-content">
        <h3 className="light-title">Basic Information</h3>
        <Confirm
          // This confirmation box handles the Force Email Verification Action
          content="Are you sure you want to Force Email Verification for all organisation users? This will send an email to all users listed as members of this organisation."
          open={this.state.forceVerifyOpen}
          onCancel={() =>
            this.setState({ ...this.state, forceVerifyOpen: false })
          }
          onConfirm={this.handleForceVerify}
        />
        <Confirm
          // This confirmation box handles the Archive Organisation Action
          open={this.state.archiveOrganisationOpen}
          onCancel={() =>
            this.setState({ ...this.state, archiveOrganisationOpen: false })
          }
          content="Are you sure you want to delete the current Organisation? This will completely remove the Organisation."
          onConfirm={this.handleOrganisationArchive}
        />
        {isFetching ? (
          <PageLoader />
        ) : (
          <div>
            <OrganisationFormFields
              handleFormSubmit={this.handleOrganisationUpdate}
              organisation={this.props.organisation}
              permission={this.props.permission}
              accountManagerAdditionAction={this.props.addOrganisationAccountManager}
              accountManagerRemovalAction={this.props.removeOrganisationAccountManager}
              industries={null}
              organisationFlags={this.props.organisationFlags}
              accountManagers={this.props.accountManagers}
              organisationTeams={this.props.organisationTeams}
              setCanHaveTeamsAction={this.props.allowToHaveTeamsOrganisation}
              refreshOrganisationTeams={this.refreshOrganisationTeams}
            >
              {restrictUserPermissions([ // All users with access can do this except support users
                INTERNAL_ADMIN,
                BUSINESS_ADMIN,
                ACCOUNT_MANAGER_ADMIN,
                STANDARD_USER
              ], permission) && (
                <li>
                  {/* // TODO: replace anchor with button // */}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    role="button"
                    tabIndex="0"
                    onClick={this.setForceVerifyModalVisible}
                    onKeyPress={this.setForceVerifyModalVisible}
                  >
                    Force Email Verification
                  </a>
                </li>
              )}
              {restrictUserPermissions([INTERNAL_ADMIN], permission) && (
                <li>
                  {/* // TODO: replace anchor with button // */}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    role="button"
                    tabIndex="-1"
                    onClick={this.setOrganisationArchiveModalVisible}
                    onKeyPress={this.setOrganisationArchiveModalVisible}
                  >
                    Delete Organisation
                  </a>
                </li>
              )}
            </OrganisationFormFields>

            {restrictUserPermissions(
              [INTERNAL_ADMIN, BUSINESS_ADMIN, SUPPORT_ADMIN, ACCOUNT_MANAGER_ADMIN],
              permission
            ) && (
              <div>
                <hr />
                <div className="additional-details">
                  <Grid columns={3} stackable>
                    <Grid.Row>
                      <Grid.Column>
                        <div className="additional-details__title">
                          Created By
                        </div>
                        {organisation.createdBy}
                      </Grid.Column>
                      <Grid.Column>
                        <div className="additional-details__title">
                          Last Changed By
                        </div>
                        {organisation.lastChangedBy}
                      </Grid.Column>
                      <Grid.Column>
                        <div className="additional-details__title">
                          Date Created
                        </div>
                        {moment
                          .utc(organisation.dateCreated)
                          .local()
                          .format('YYYY-MM-DD')
                          .toString()}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <div className="additional-details__title">
                          Date Modified
                        </div>
                        {moment
                          .utc(organisation.dateUpdated)
                          .local()
                          .format('YYYY-MM-DD')
                          .toString()}
                      </Grid.Column>
                      <Grid.Column>
                        <div className="additional-details__title">
                          Last Forced Verify Account
                        </div>
                        {organisation.lastForceVerifyAccountTime}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  isFetching: state.organisations.isFetching || state.organisationAccountManagers.isFetching,
  industries: getIndustriesSortedAlphabetically(state),
  organisationFlags: getOrganisationFlagsSortedById(state),
  accountManagers: getOrganisationAccountManagersSortedAlphabetically(state),
  organisationTeams: getOrganisationTeamsSortedAlphabetically(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  // $FlowFixMe TODO(DP): Fix this
  bindActionCreators(
    {
      updateOrganisation,
      loadIndustries,
      archiveOrganisation,
      forceVerifyOrganisation,
      loadOrganisationFlags,
      loadOrganisationAccountManagers,
      addOrganisationAccountManager,
      removeOrganisationAccountManager,
      loadAllOrganisationTeams,
      allowToHaveTeamsOrganisation
    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(Details): any);
