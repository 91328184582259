//@flow
import * as React from 'react';
import {
  Grid,
  Form,
  Dropdown,
  Button,
  Modal,
} from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react'; //ToDo: Current version produces warning.  Developer has stated that it will be fixed in next release.  https://github.com/arfedulov/semantic-ui-calendar-react/issues/56
import moment from 'moment';
import { restrictUserPermissions, permissions } from 'roy-morgan-auth';
import OverflowMenu from '../OverflowMenu';
import StaticField from '../StaticField';
import type {
  Organisation as OrgType,
  NewOrganisation as NewOrgType,
  Industry as IndustryType,
  OrganisationForm,
  AuthTypes,
  OrganisationFlag as OrganisationFlagType,
  AccountManager as AccountManagerType
} from '../../types';
import SetAccountManagers from '../../containers/SetAccountManagers';
import  TransformOrganisationToHaveTeams from './TransformOrganisationToHaveTeams';


type Props = {
  permission: ?AuthTypes,
  industries: ?Array<IndustryType>,
  organisationFlags: ?Array<OrganisationFlagType>,
  organisation: OrgType | NewOrgType,
  accountManagers: Array<AccountManagerType>,
  handleFormSubmit: Function,
  refreshOrganisationTeams: Function,
  accountManagerAdditionAction: (oid: number, uid: number) => Promise<AccountManagerType>,
  accountManagerRemovalAction: (oid: number, uid: number) => Promise<boolean>,
  setCanHaveTeamsAction: (data: any) => Promise<any>,
  organisationTeams:?Array<any>,
  children?: any,
};

type State = {
  formData: OrganisationForm,
  setAccountManagerOpen?: boolean,
  organisationName: ?string,
  organisationNotes: ?string,
  organisationNextReviewDate: ?string,
  setCanHaveTeamOpen ?: boolean,
};

const dateFormat = 'DD/MM/YYYY';

const industryIdFromProps = (props: Props) => {
  return props.organisation && props.organisation.industryId
    ? props.organisation.industryId
    : null;
};

const organisationFlagIdFromProps = (props: Props) => {
  return props.organisation && props.organisation.organisationFlagId
    ? props.organisation.organisationFlagId
    : null;
};

class OrganisationFormFields extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      formData: {},
      organisationName: props.organisation.name,
      organisationNotes: props.organisation.notes,
      organisationNextReviewDate: props.organisation.nextReviewDate
    };
    if (
      restrictUserPermissions(
        [
          permissions.INTERNAL_ADMIN,
          permissions.BUSINESS_ADMIN,
          permissions.SUPPORT_ADMIN,
          permissions.ACCOUNT_MANAGER_ADMIN
        ],
        props.permission
      )
    ) {
      this.state = {
        formData: {
          ...this.state.formData,
          name: props.organisation.name,
          notes: props.organisation.notes,
          nextReviewDate: props.organisation.nextReviewDate,
          industryId: industryIdFromProps(props),
          organisationFlagId: organisationFlagIdFromProps(props)
        },
        organisationName: props.organisation.name,
        organisationNotes: props.organisation.notes,
        organisationNextReviewDate: props.organisation.nextReviewDate,
        setAccountManagerOpen: false,
        setCanHaveTeamOpen: false
      };
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      restrictUserPermissions(
        [
          permissions.INTERNAL_ADMIN,
          permissions.BUSINESS_ADMIN,
          permissions.SUPPORT_ADMIN,
          permissions.ACCOUNT_MANAGER_ADMIN
        ],
        nextProps.permission
      )
    ) {
      this.setState({
        formData: {
          ...this.state.formData,
          name: nextProps.organisation.name,
          notes: nextProps.organisation.notes,
          nextReviewDate: nextProps.organisation.nextReviewDate,
          industryId: industryIdFromProps(nextProps),
          organisationFlagId: organisationFlagIdFromProps(nextProps)
        }
      });
    }
  }

  handleDropdownChange: (
    event: SyntheticInputEvent<HTMLSelectElement>,
    result: { value: number, ... }
  ) => void = (
    event: SyntheticInputEvent<HTMLSelectElement>,
    result: { value: number }
  ) => {
    this.setState({
      formData: {
        ...this.state.formData,
        industryId: result.value,
      },
    });
  };

  handleDropdownChangeOrganisationFlag: (
    event: SyntheticInputEvent<HTMLSelectElement>,
    result: { value: number, ... }
  ) => void = (
    event: SyntheticInputEvent<HTMLSelectElement>,
    result: { value: number }
  ) => {
    this.setState({
      formData: {
        ...this.state.formData,
        organisationFlagId: result.value,
      },
    });
  };

  handleDateChange: (event: any, { name: any, value: any, ... }) => void = (
    event: any,
    { name, value }: { name: any, value: any }
  ) => {
    let formData = Object.assign({}, this.state.formData);
    formData[name] = value ? moment(value, dateFormat).toISOString() : null;
    this.setState({ formData });
  };

  handleInputChange: (event: SyntheticInputEvent<HTMLInputElement>) => void = (
    event: SyntheticInputEvent<HTMLInputElement>
  ) => {
    let formData = Object.assign({}, this.state.formData);
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  handleAccountManagerAddition: (userId: number) => void = (userId: number) => {
    this.props
      .accountManagerAdditionAction(this.props.organisation.id, userId)
        .then(
          (newManager) => {
            this.setState({ setAccountManagerOpen: false });
          },
          (failure) => {}
        );
  };

  handleAccountManagerRemoval: (userId: number) => void = (userId: number) => {
    this.props
      .accountManagerRemovalAction(this.props.organisation.id, userId)
        .then(
          () => {
            this.setState({ setAccountManagerOpen: false });
          },
          (failure) => {}
        );
  };

  handleSetCanHaveTeams: (data: any) => void = (data: any) => {
    this.props.refreshOrganisationTeams();
  };

  toggleSetAccountManagerModal: (
    event: SyntheticInputEvent<HTMLInputElement>
  ) => void = (event: SyntheticInputEvent<HTMLInputElement>) => {
    event.preventDefault();
    this.setState({
      setAccountManagerOpen: !this.state.setAccountManagerOpen,
    });
  };

  toggleSetCanHaveTeamModal: (
    event: SyntheticInputEvent<HTMLInputElement>
  ) => void = (event: SyntheticInputEvent<HTMLInputElement>) => {
    event.preventDefault();
    this.setState({
      setCanHaveTeamOpen: !this.state.setCanHaveTeamOpen,
    });
  };


  render(): React.Node {
    const {
      handleFormSubmit,
      industries,
      permission,
      organisationFlags,
      accountManagers
    } = this.props;
    let name, industryId, organisationFlagId, notes, nextReviewDate;
    const {
      setAccountManagerOpen,
      organisationName,
      organisationNotes,
      organisationNextReviewDate,
    } = this.state;

    const {
      INTERNAL_ADMIN,
      BUSINESS_ADMIN,
      SUPPORT_ADMIN,
      ACCOUNT_MANAGER_ADMIN,
      STANDARD_USER
    } = permissions;

    let accountManagersDisplay = accountManagers
      .map(m => m.name)
      .join(', ');

    if (
      restrictUserPermissions(
        [INTERNAL_ADMIN, BUSINESS_ADMIN, SUPPORT_ADMIN, ACCOUNT_MANAGER_ADMIN],
        permission
      )
    ) {
      name = this.state.formData.name;
      notes = this.state.formData.notes;
      nextReviewDate = this.state.formData.nextReviewDate;
      industryId = this.state.formData.industryId;
      organisationFlagId = this.state.formData.organisationFlagId;
    } else {
      name = organisationName ? organisationName : '';
      notes = organisationNotes ? organisationNotes : '';
      nextReviewDate = organisationNextReviewDate
        ? organisationNextReviewDate
        : '';
      industryId = industryIdFromProps(this.props);
      organisationFlagId = organisationFlagIdFromProps(this.props);
    }

    const nextReviewDateFormatted = nextReviewDate
      ? moment(nextReviewDate).format(dateFormat)
      : '';

    const industryOptions = industries
      ? industries.map((industry: IndustryType) => {
          return {
            text: industry.name,
            value: industry.id,
          };
        })
      : null;

    const organisationFlagOptions = organisationFlags
      ? organisationFlags.map((organisationFlag: OrganisationFlagType) => {
          return {
            text: organisationFlag.name,
            value: organisationFlag.id,
          };
        })
      : null;

    const renderOrganisationHaveTeams = (organisation, organisationTeams) =>{
      if (organisation.canHaveTeams){
        const l = Array.isArray(organisationTeams) ? organisationTeams.length : 0;
        const text = 'This Organisation has ' + l + ' Teams'
          return (
            <Grid.Row>
              <Grid.Column width={12}>
                <StaticField
                  label="Teams"
                  value={text}
                />
              </Grid.Column>
            </Grid.Row>
          )
      }
       return (
        <Grid.Row>
          <Grid.Column width={12}>
            <StaticField
              label="Teams"
              value="Organisation doesn't allow to have teams"
            />
            <div>
              <Button onClick={this.toggleSetCanHaveTeamModal}>
                Arrange this organisation into teams
              </Button>
            </div>
          </Grid.Column>
        </Grid.Row>
      )
    }

    return (
      <Form
        className="organisation-form"
        onSubmit={() => {
          handleFormSubmit(this.state.formData);
        }}
      >

        <Modal
          open={this.state.setCanHaveTeamOpen}
          closeIcon={<button className="modal__close">Close</button>}
          onClose={() =>this.setState({ ...this.state, setCanHaveTeamOpen: false })}
          style={  {modal : {
            marginTop: '0px !important',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}}

        >
          <Modal.Header>Arrange this organisation into teams</Modal.Header>
          <Modal.Content>
            <TransformOrganisationToHaveTeams
              handleFormSubmit={this.handleSetCanHaveTeams}
              setCanHaveTeamsAction={this.props.setCanHaveTeamsAction}
              OrganisationId={this.props.organisation.id}
              >
            </TransformOrganisationToHaveTeams>
          </Modal.Content>
        </Modal>
        <Grid columns={2} stackable>
          <Grid.Row>
            <Grid.Column>
              {restrictUserPermissions(
                [INTERNAL_ADMIN, BUSINESS_ADMIN, ACCOUNT_MANAGER_ADMIN],
                permission
              ) ? (
                <Form.Input
                  label="Organisation Name"
                  name="name"
                  value={name}
                  onChange={this.handleInputChange}
                  type="text"
                  required
                />
              ) : (
                <StaticField label="Organisation Name" value={name} />
              )}
            </Grid.Column>
          </Grid.Row>
          {restrictUserPermissions([
            INTERNAL_ADMIN,
            BUSINESS_ADMIN,
            SUPPORT_ADMIN,
            ACCOUNT_MANAGER_ADMIN
          ], permission) ? (
            <Grid.Row>
              <Grid.Column width={12}>
                <Form.TextArea
                  label="Notes"
                  name="notes"
                  value={notes}
                  onChange={this.handleInputChange}
                  placeholder="Enter your notes"
                  rows={10}
                />
              </Grid.Column>
            </Grid.Row>
          ) : (
            ''
          )}
          {restrictUserPermissions([
            INTERNAL_ADMIN,
            BUSINESS_ADMIN,
            SUPPORT_ADMIN,
            ACCOUNT_MANAGER_ADMIN
          ], permission) ? (
            <Grid.Row>
              <Grid.Column>
                <DateInput
                  label="Next Review Date"
                  name="nextReviewDate"
                  placeholder="next review date"
                  value={nextReviewDateFormatted}
                  initialDate={nextReviewDateFormatted}
                  iconPosition="left"
                  onChange={this.handleDateChange}
                />
              </Grid.Column>
            </Grid.Row>
          ) : (
            ''
          )}
          {restrictUserPermissions([
            INTERNAL_ADMIN,
            BUSINESS_ADMIN,
            SUPPORT_ADMIN,
            ACCOUNT_MANAGER_ADMIN
          ], permission) ? (
            <Grid.Row>
              <Grid.Column width={12}>
                <StaticField
                  label="Account Managers"
                  value={accountManagersDisplay}
                />
                <div>
                  <Button onClick={this.toggleSetAccountManagerModal}>
                    Set Account Managers
                  </Button>
                  <Modal
                    open={setAccountManagerOpen}
                    closeIcon={<button className="modal__close">Close</button>}
                    onClose={this.toggleSetAccountManagerModal}
                  >
                    <Modal.Header>Set Account Managers</Modal.Header>
                    <Modal.Content>
                      <SetAccountManagers
                        accountManagers={accountManagers}
                        addAccountManager={this.handleAccountManagerAddition}
                        removeAccountManager={this.handleAccountManagerRemoval}
                      />
                    </Modal.Content>
                  </Modal>
                </div>
              </Grid.Column>
            </Grid.Row>
          ) : (
            ''
          )}
          {restrictUserPermissions([
            INTERNAL_ADMIN,
            BUSINESS_ADMIN,
            ACCOUNT_MANAGER_ADMIN
          ], permission) ? (
            renderOrganisationHaveTeams(this.props.organisation, this.props.organisationTeams)
          ) : (
            ''
          )}
          <Grid.Row>
            <Grid.Column width={12}>
              {restrictUserPermissions([
                INTERNAL_ADMIN,
                BUSINESS_ADMIN,
                SUPPORT_ADMIN,
                ACCOUNT_MANAGER_ADMIN
              ], permission) &&
              (industryOptions || organisationFlagOptions) ? (
                <IndustryOrganisationFlagForm
                  industryOptions={industryOptions}
                  industryId={industryId}
                  permission={permission}
                  handleDropdownChange={this.handleDropdownChange}
                  organisationFlagOptions={organisationFlagOptions}
                  organisationFlagId={organisationFlagId}
                  handleDropdownChangeOrganisationFlag={
                    this.handleDropdownChangeOrganisationFlag
                  }
                />
              ) : (
                ''
              )}
              <div className="organisation-form__actions">
                <Button
                  className="organisation-form__submit"
                  type="submit"
                  disabled={permission === STANDARD_USER}
                >
                  Submit
                </Button>
                {this.props.children ? (
                  <OverflowMenu dark={true} leftAlign={true}>
                    {this.props.children}
                  </OverflowMenu>
                ) : (
                  ''
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

const IndustryOrganisationFlagForm = (props: {
  industryOptions: ?(Object[]),
  industryId: ?number,
  organisationFlagOptions: ?(Object[]),
  organisationFlagId: ?number,
  permission: ?AuthTypes,
  handleDropdownChange: (
    event: SyntheticInputEvent<HTMLSelectElement>,
    result: { value: number }
  ) => void,
  handleDropdownChangeOrganisationFlag: (
    event: SyntheticInputEvent<HTMLSelectElement>,
    result: { value: number }
  ) => void,
}) => {
  return (
    <div>
      {props.organisationFlagOptions ? (
        <Form.Field>
          <label htmlFor="organisationFlagId">Organisation Flag</label>
          <Dropdown
            onChange={props.handleDropdownChangeOrganisationFlag}
            name="industryId"
            value={props.organisationFlagId}
            fluid
            selection
            placeholder={'Select Organisation Flag'}
            options={props.organisationFlagOptions}
          />
          <br />
        </Form.Field>
      ) : (
        ''
      )}
    </div>
  );
};

export default OrganisationFormFields;
